import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: () => import('@/components/HelloWorld.vue')
    // }
    // {
    //   path: '/',
    //   name: 'MainPage',
    //   component: () => import('../components/MarketingPage.vue')
    // },
    {
        path: '/',
        name: 'MainPage',
        component: () => import('../components/MainPage.vue'),
    },
    {
        path: '/Introduction',
        name: 'Introduction',
        component: () => import('../components/Introduction/IntroductionPage.vue'),
    },
    {
        path: '/Marketing',
        name: 'Marketing',
        component: () => import('../components/Introduction/MarketingPage.vue'),
    },
    {
        path: '/ChellengeApply',
        name: 'ChellengeApply',
        component: () => import('../components/Introduction/Challenge/ApplyPage.vue'),
    },
    {
        path: '/ChellengeDetail',
        name: 'ChellengeDetail',
        component: () => import('../components/Introduction/Challenge/DetailPage.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
